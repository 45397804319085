import React from "react";
import Header from "../Header/Header";
import { NavLink, Switch, Route } from "react-router-dom";
import Bride from "../Form/Bride";
import Visitor from "../Form/Visitor";
import style from "./css/eventtwofrom.module.css";

const EventTwoFrom = (props) => {
  const parentPath = props.match.path;
  return (
    <>
      <Header color="black" />
      <div className={style.mainbodyfrm}>
        <div style={{ textAlign: "center" }} className="header-cls">
          <h1>GET YOUR EVENT INVITE</h1>
          <p style={{ color: "#A8A6A6" }}>
            Are you excited about your wedding? We sure are! Fill out this form
            for the best to be right at your disposal!
          </p>
        </div>
      </div>
      <div className={style.widthContainer}>
        <ul className={style.navlinks}>
          <NavLink
            activeClassName={style.active}
            to={parentPath + "/bride-to-be"}
          >
            <li style={{ margin: 20 }}>Bride To be</li>
          </NavLink>
          <NavLink activeClassName={style.active} to={parentPath + "/visitors"}>
            <li style={{ margin: 20 }}>Visitor</li>
          </NavLink>
        </ul>
      </div>
      <Switch>
        <Route
          component={() => <Bride />}
          exact
          path={[parentPath, parentPath + "/bride-to-be"]}
        />
        <Route
          component={() => <Visitor />}
          path={[parentPath + "/visitors"]}
        />
      </Switch>
      <p
        style={{
          textAlign: "left",
          width: "600px",
          margin: "0 auto",
          paddingBottom: "12px",
        }}
      >
        Hope to connect and make your big day a special one!
      </p>
    </>
  );
};

export default EventTwoFrom;
