import React from 'react'
import style from "../../layout/ContactUs/Css/contact.module.css"
import {FcCheckmark} from "react-icons/fc"

function ExhbitiThank(props) {

    const handleClick = () => {
        window.location.href='https://weddingasiaonlineshop.sp-seller.webkul.com/index.php?p=signup';
    };
    return (
      
             <div style={{textAlign:'center'}} className={style.container}>
            <h1 className={style.head}>Want to sell your product online?
</h1>

            {/*<p className={style.contact}>Call us at: <a href="#">+91</a> or meet us at <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, repellat!</a></p>*/}
            <div className={style.checked}><FcCheckmark size="50"/><p>{props.data}</p></div>
            <p>{props.msg}</p>
            <button onClick={handleClick} type="button" class="btn">BECOME ONLINE SELLER</button>
            <p>CLICK HERE TO KNOW MORE</p>
        </div>
   
    )
}

export default ExhbitiThank