import React,{useState,useEffect} from 'react';
import axios from "axios";
import DOMPurify from "dompurify";
import Header from "../../components/Header/Header";
import style from './css/privacypolicy.module.css'

const PrivacyPolicy = () => {
    const [privacy, setPrivacy] = useState([]);
    useEffect(() => {
        axios.get('https://backend.weddingasia.in/privacy-policy')
            .then((res)=>setPrivacy(res.data))
            .catch((err)=>console.log(err))
    }, []);

    return (
        <div>
            <Header color="black"/>
            <section className={style.mainbody}  dangerouslySetInnerHTML={{__html: privacy?.body !== undefined ? DOMPurify.sanitize(privacy?.body) : ""}}>

            </section>
        </div>
    );
};

export default PrivacyPolicy;
