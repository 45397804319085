import React from "react";
import "./css/PlanCard.css";
import ProgressiveImage from "react-progressive-graceful-image";
import { placeholderImage } from "../../../config/config";
function PlanCard(props) {
  const { plan } = props;
  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });
  const p = formatter.format(plan.price);
  return (
    <div className="bm">
      <a href={plan.link} target="_blank" rel="noreferrer">
        <ProgressiveImage src={plan.image} placeholder={placeholderImage}>
          {(src, loading) => (
            <img
              style={{ opacity: loading ? 0.5 : 1 }}
              src={src}
              alt={plan.title}
            />
          )}
        </ProgressiveImage>
        {/*<img*/}
        {/*    src={plan.image}*/}
        {/*    alt={plan.title}  className="fexhb-img"/>*/}
      </a>
      <a href={plan.link} target="_blank" rel="noreferrer">
        <p className="featured-p-padding">{plan.title}</p>
      </a>
      <p>Rs.{p}</p>
    </div>
  );
}

export default PlanCard;
