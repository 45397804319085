import React, { useState, useEffect, createRef } from 'react';
import style from './css/galleryDetails.module.css';
import styles from "../css/gallery.module.css"

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import axios from "axios";
import Header from "../../../components/Header/Header";
import { useParams } from 'react-router-dom'
import WeddingCard from "../../../components/WeddingCard/WeddingCard";
// import { SRLWrapper } from 'simple-react-lightbox';


const GalleryDetails = () => {
    const param = useParams();
    const mainRef = createRef()
    const [imageGallery, setImageGallery] = useState({});
    const [feature, setFeature] = useState({});
    const [loadMore, setLoadMore] = useState(2);
    const [lightBoxData, setLightBoxdata] = useState([]);
    const [openLightBox, setOpenLightBox] = useState(false);
    useEffect(() => {
        mainRef.current.scrollIntoView();
    }, [mainRef])
    useEffect(() => {

        axios.get(`https://backend.weddingasia.in/galleries/${param.title}`)
            .then((res) => setImageGallery(res.data))
            .catch((err) => console.log(err))
    }, [param]);

    useEffect(() => {
        axios.get(`https://backend.weddingasia.in/galleries/related-galleries/${param.title}`)
            .then((res) => setFeature(res.data))
            .catch((err) => console.log(err))
    }, [param]);

    const showMore = () => {
        setLoadMore((prev) => prev + 1)
        axios.get(`https://backend.weddingasia.in/galleries/related-galleries/${param.title}?page=${loadMore}`)
            .then((res) => {
                setFeature(prevState => ({ ...prevState, results: [...prevState.results, ...res.data.results] }))
                setFeature(prevState => ({ ...prevState, next: res.data.next }))
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        const lightboxImages = []
        imageGallery?.photos?.forEach((item) => {
            lightboxImages.push({ src: item.photos })
        })
        setLightBoxdata(lightboxImages)
    }, [imageGallery])
    return (<>
        <Header color="black" />
        <div ref={mainRef} className={style.des}>
            <h1>{imageGallery.title}</h1>
            <p>{imageGallery.description}</p>
        </div>
        <Lightbox
            open={openLightBox}
            close={() => setOpenLightBox(false)}
            slides={lightBoxData}
        />
        <div className={style.galerybody}>

            {imageGallery?.photos?.map((item) =>
                <figure key={item.id} className={style.galleryitem} onClick={() => setOpenLightBox(true)}>
                    <a href={"#"} className="glightbox" > <img src={item.photos} alt="" /></a>
                </figure>
            )}

        </div>

        <div>
            <h2 className="featured-gallery" style={{
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Nunito, sans-serif",
                letterSpacing: "4px",
                marginTop: "42px"
            }}>
                FEATURED GALLERY
            </h2>
            <div className={style.featred}>
                {feature?.results?.map((item) => <WeddingCard key={item.id} item={item} />)}
            </div>
            {feature?.next ?
                <div id="show-more" className={styles.showconatainer}>
                    <button onClick={showMore} className={styles.showmorebutton}>
                        show more events &#8794;
                    </button>
                </div> : ''}
        </div>
    </>

    );
}

export default GalleryDetails;
