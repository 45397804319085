import React, {useState,useEffect} from 'react'
import "./css/Plans.css";
import PlanCard from '../FeaturedColection/PlanCard/PlanCard';
import axios from "axios";
function Plans() {

    const [plansList, setPlansList] = useState([]);
    useEffect(() => {
        axios.get('https://backend.weddingasia.in/featuredcollection/get-all-collections')
            .then((res)=>setPlansList(res.data))
            .catch((err)=>console.log(err))
    }, []);


    return (
        <section className="plans__grid">
            {plansList?.map((plan,i) => <PlanCard plan={plan} key={i}/>)}
        </section>
    )
}

export default Plans

