import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
//import { BrideDetail } from "../components/BrideStroy/BrideDetailPage/BrideDetail";
//import { BrideStory } from "../components/BrideStroy/BrideStory";
import Skeleton from "../components/Skeleton/Skeleton";
//import BlogDetail from "../layout/BlogDetail/BlogDetail";
// import SimpleReactLightbox from "simple-react-lightbox";
import { Home } from "../layout/Home/Home";
import ExhibitorForm from "../components/ExhibitorForm/ExhibitorForm";
import EventTwoFrom from "../components/EventInvitationTwoFrm/EventTwoFrom";
import EventDetail from "../layout/EventDetail/EventDetail";
import ExhibitorDetails from "../layout/ExhibitDetails/ExhibitWithUsDetails";

import PrivacyPolicy from "../layout/PrivacyPolicy/PrivacyPolicy";
import TermAndCondition from "../layout/TermsAndConditions/TermAndCondition";
import WorkWithUs from "../layout/WrokWithUs/WorkWithUs";
import WorkDetail from "../layout/WrokWithUs/WorkDetail/WrokDetail";
import ContactUs from "../layout/ContactUs/Contact";
import ContactUsThankYou from "../layout/ThankYou/ContactUsThankYou";
import Gallery from "../layout/Gallery/Gallery";
import GalleryDetails from "../layout/Gallery/GalleryDetails/GalleryDetails";
import EventScreen from "../layout/UpComingEvent/EventScreen";
import MixBlog from "../layout/MixBlog/MixBlog";
import EventInviteSucessScreen from "../layout/SucessScreen/EventInviteSucessScreen";

const BlogDetail = lazy(() => import("../layout/BlogDetail/BlogDetail"));
const BrideStory = lazy(() => import("../components/BrideStroy/BrideStory"));
const BrideDetail = lazy(() =>
  import("../components/BrideStroy/BrideDetailPage/BrideDetail")
);
const Navigation = () => {
  return (
    <Switch>
      <Route component={Home} exact path="/" />
      <Route exact path="/event-invite/thank-you/:msg">
        <EventInviteSucessScreen />
      </Route>
      <Route component={EventTwoFrom} path="/event-invite" />
      <Route exact path="/galleries">
        <Gallery />
      </Route>
      <Route exact path="/journal">
        <MixBlog />
      </Route>
      <Route exact path="/galleries/:title">
        {/* <SimpleReactLightbox> */}
        <GalleryDetails />
        {/* </SimpleReactLightbox> */}
      </Route>
      <Route exact path="/privacy-policy/">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/contact-us/">
        <ContactUs />
      </Route>
      <Route exact path="/contact-us/thankyou">
        <ContactUsThankYou />
      </Route>
      <Route exact path="/work-with-us/">
        <WorkWithUs />
      </Route>
      <Route exact path="/terms-and-conditions/">
        <TermAndCondition />
      </Route>
      <Route exact path="/exhibit-with-us/exhibitor-query-form">
        <ExhibitorForm />
      </Route>
      <Route exact path="/work-with-us/:title">
        <WorkDetail />
      </Route>
      <Route exact path="/events/event/:title">
        <EventDetail />
      </Route>
      <Route exact path="/events/upcomming-events/">
        {/*<UpcomingEvent/>*/}
        <EventScreen />
      </Route>
      <Route exact path="/exhibit-with-us">
        <ExhibitorDetails />
      </Route>
      <Route exact path="/wedding-stories">
        <Suspense fallback={<Skeleton type="eventInsideCover" />}>
          <BrideStory />
        </Suspense>
      </Route>
      <Route exact path="/wedding-stories/:title">
        <Suspense fallback={<Skeleton type="eventInsideCover" />}>
          <BrideDetail />
        </Suspense>
      </Route>
      <Route exact path="/blog/:title">
        <Suspense fallback={<Skeleton type="eventInsideCover" />}>
          <BlogDetail />
        </Suspense>
      </Route>
      <Route exact path="*">
        <Home />
      </Route>
    </Switch>
  );
};

export default Navigation;
