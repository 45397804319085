import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./css/carousel.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";
function Carousel() {
  const [sliderList, setSliderList] = useState([]);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/sliders/get-all-sliders")
      .then((res) => setSliderList(res.data))
      .catch((err) => console.log(err));
  }, []);

  // const sliderList = [
  //   {
  //     PhoneImg: "http://3.6.36.189/media/slide/images/1_1.jpg",
  //     desktopImg: "http://3.6.36.189/media/slide/images/1_1.jpg",
  //     event: "events",
  //     heading: "wedding asia in ludhiana this july",
  //   },
  //   {
  //     PhoneImg: "http://3.6.36.189/media/slide/images/1_1.jpg",
  //     desktopImg: "http://3.6.36.189/media/slide/images/1_1.jpg",
  //     event: "events",
  //     heading: "wedding asia in ludhiana this july",
  //   },
  //   {
  //     PhoneImg: "http://3.6.36.189/media/slide/images/1_1.jpg",
  //     desktopImg: "http://3.6.36.189/media/slide/images/1_1.jpg",
  //     event: "events",
  //     heading: "wedding asia in ludhiana this july",
  //   },
  // ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleCopy = (phoneNumber) => {
    if (!navigator.userAgent.match(/Android/i)) {
      navigator.clipboard.writeText(phoneNumber);
      toast.success("Number Copied!");
    }
  };
  return (
    <Slider {...settings}>
      {sliderList?.map((data, i) => {
        return (
          <div className="slider-container" key={i}>
            <img
              src="http://3.6.36.189/media/slide/images/1_1.jpg"
              alt={data.title}
              className="slider__img"
              srcSet={`${data.mobile_image} 640w, ${data.tab_image} 960w, ${data.desktop_image} 1024w`}
            />
            <div className="slider-content">
              <p>{data.title}</p>
              <h2>{data.subtitle}</h2>
              {/* <button className="slider-btn"><a href={data.btnUrl}>{data.buttontitle}</a></button> */}
              <button className="slider-btn">
                <Link to={data.btnUrl}>{data.buttontitle}</Link>
              </button>
              <p>
                For more info, Call&nbsp;
                <a
                  onClick={() => handleCopy("9216322509")}
                  href="wtai://wp/mc;9216322509"
                  style={{
                    color: "white",
                    fontWeight: "900",
                  }}
                >
                  92163 22509
                </a>
              </p>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}

export default Carousel;
