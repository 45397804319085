import React from "react";
import style from "./css/evencard.module.css";
import { Link } from "react-router-dom";

const EventCard = (props) => {
  const changeTitle = (item) => {
    let text = item.split(" ");
    let first = text.shift();
    let updatedItem = `<span class=firstword>${first} </span>` + text.join(" ");
    return updatedItem;
  };
  return (
    <>
      <div className={style.event_container}>
        <h2
          className={style.title}
          dangerouslySetInnerHTML={{ __html: changeTitle(props.item.title) }}
        ></h2>
        <div className="desc">
          <h3 className={style.eventPlace}>{props.item.venue}</h3>
          <h3 className={style.evetnDate}>{props.item.event_date}</h3>
        </div>
        <div className={style.link}>
          <a href="/event-invite/visitors">
            <button className={style.eventRegis}>FOR INVITATION</button>
          </a>
          <Link
            onClick={(e) => e.preventDefault()}
            to={"/events/event/" + props.item.slug}
          >
            <button className={style.knoemOre}>
              For more info, <br />
              Call 92163 22509{" "}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default EventCard;
