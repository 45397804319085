import React, { useState, useEffect, createRef } from "react";
import "./css/exhibit.css";
import Header from "../../components/Header/Header";
import { GrWorkshop } from "react-icons/gr";
import { VscAccount } from "react-icons/vsc";
import { IoLocationSharp } from "react-icons/io5";
import {
  AiFillYoutube,
  AiOutlineDownload,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { TiSocialFacebook } from "react-icons/ti";
import { Link } from "react-router-dom";
import axios from "axios";
import UpcomingEvent from "../../components/UpComingEvent/UpcomingEvent";
import Testimonial from "../../components/Testimonial/Testimonial";
import Procedure from "../../components/Procedure/Procedure";
import { useHistory } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { FaPlay } from "react-icons/fa";
const ExhibitorDetails = () => {
  const [exhibit, setExhibit] = useState({});
  //   const [EventPage, setEventPage] = useState({});
  const [logo, setLogo] = useState([]);
  const mainRef = createRef();
  const mainsRef = createRef();
  const secondRef = createRef();
  const whyRef = createRef();
  const history = useHistory();
  const [isOpen1, setOpen1] = useState(false);
  const handelrclick = () => {
    let path = `/exhibit-with-us/exhibitor-query-form`;
    history.push(path);
  };
  const clickhandler = () => {
    mainRef.current.scrollIntoView();
  };
  const testhandler = () => {
    secondRef.current.scrollIntoView();
  };
  const whythandler = () => {
    whyRef.current.scrollIntoView();
  };
  useEffect(() => {
    axios
      .get(`https://backend.weddingasia.in/exhibitor/exhibit-with-us`)
      .then((res) => setExhibit(res.data))
      .catch((err) => console.log(err));
  }, []);
  //   useEffect(() => {
  //     axios
  //       .get("https://backend.weddingasia.in/events/page-details")
  //       .then((res) => setEventPage(res.data))
  //       .catch((err) => console.log(err));
  //   }, []);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/partner/")
      .then((res) => setLogo(res.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    mainsRef.current.scrollIntoView();
  }, [mainsRef]);
  return (
    <>
      <Header color="black" />
      <div
        ref={mainsRef}
        id="hxhbity"
        className="event__hero"
        style={{
          backgroundImage: `linear-gradient(269deg, #000000 0%, #00000075 0%, #000000 100%),
      url(${exhibit?.coverimage})`,
        }}
      >
        <div className="event__hero-container">
          <h1 className="head--1 event__hero-head">
            {exhibit?.herosectiontitle}
          </h1>
          <p className="heroIntroi"> {exhibit?.herosectionintro}</p>
          <div className="btns">
            <Link to="/exhibit-with-us/exhibitor-query-form" className="btn">
              {" "}
              {exhibit?.herosectionbuttontitle}
            </Link>
          </div>
        </div>
        <div className="event__hero-location">
          <div className="event__hero-locate">
            <div className="event__hero-media">
              <GrWorkshop size="28" />
              <div onClick={whythandler}>
                <div>{exhibit.boxonetitle}</div>
                {/* <div>20+ workshop</div> */}
              </div>
            </div>
            <div className="event__hero-media">
              <VscAccount size="28" />
              <div onClick={testhandler}>
                <div>{exhibit.boxtwotitle}</div>
                {/*<div>20+ workshop</div>*/}
              </div>
            </div>
            <div onClick={clickhandler} className="event__hero-media">
              <IoLocationSharp size="28" />
              <div>
                <div>{exhibit.boxthreetitle}</div>
                {/*<div>20+ workshop</div>*/}
              </div>
            </div>
            <div className="event__hero-media">
              <a href="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/About+Wedding+Asia.pdf">
                <AiOutlineDownload size="28" />
                <div>
                  <div>{exhibit.boxfourtitle}</div>
                  {/*<div>20+ workshop</div>*/}
                </div>
              </a>
            </div>
          </div>
          <div className="event-scroll">
            scroll <BsArrowRight size="30" style={{ marginLeft: "10px" }} />
          </div>
          <div className="even__hero-share">
            <div>
              <a
                rel="noreferrer"
                style={{ color: "white" }}
                target="_blank"
                href="https://twitter.com/weddingasia_?lang=en"
              >
                <AiOutlineTwitter /> <span>Twitter</span>
              </a>
            </div>
            <div>
              <a
                rel="noreferrer"
                style={{ color: "white" }}
                target="_blank"
                href="https://www.facebook.com/weddingasiaofficial/"
              >
                <TiSocialFacebook />
                <span style={{ fontSize: 14 }}>Facebook</span>
              </a>
            </div>
            <div>
              <a
                rel="noreferrer"
                style={{ color: "white" }}
                target="_blank"
                href="https://www.youtube.com/channel/UCZqmNv-JUj0_evWZPOke1Pg"
              >
                <AiFillYoutube /> <span style={{ fontSize: 14 }}>Youtube</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="event__hero-locate media-mobile">
        <div className="event__hero-media">
          <GrWorkshop size="28" />
          <div onClick={whythandler}>
            <div>{exhibit.boxonetitle}</div>
            {/* <div>20+ workshop</div> */}
          </div>
        </div>
        <div className="event__hero-media">
          <VscAccount size="28" />
          <div onClick={testhandler}>
            <div>{exhibit.boxtwotitle}</div>
            {/*<div>20+ workshop</div>*/}
          </div>
        </div>
        <div className="event__hero-media">
          <IoLocationSharp size="28" />
          <div onClick={clickhandler}>
            <div>{exhibit.boxthreetitle}</div>
            {/*<div>20+ workshop</div>*/}
          </div>
        </div>
        <div className="event__hero-media">
          <a href="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/About+Wedding+Asia.pdf">
            <AiOutlineDownload size="28" />
            <div>
              <div>{exhibit.boxfourtitle}</div>
              {/*<div>20+ workshop</div>*/}
            </div>
          </a>
        </div>
      </div>
      <section className="event__media-container">
        <div className="event__media grid grid-cols-2 grid-gap">
          <img src={exhibit?.WEWUimage} alt="" className="event__media-img" />
          <div ref={whyRef}>
            <h1 className="event__media-head">WHY EXHIBIT WITH US</h1>
            <h2 className="event__media-year">{exhibit.this_year}</h2>
            <div className="event__media-texts grid">
              <p className="event__media-text text--secondary">
                {exhibit?.WEWUparaone}
              </p>
              <p className="event__media-text text--secondary">
                {exhibit?.WEWUparaone}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="frcard">
        <div className="four-box-row">
          {exhibit?.fourbox?.map((item) => (
            <div key={item.id} className="r">
              <h2>{item.boxtitle}</h2>
              <p>{item.boxdescription}</p>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="reg-with-para">
          <p>
            FOR MORE INFORMATION REGARDING PARTICIPATION, SPONSORSHIP,STALL
            AVAILIBILITY AND ADVICE
          </p>
          <p>click here</p>
          <Link to="/exhibit-with-us/exhibitor-query-form">
            <button type="button" className="registration-button-class">
              <a
                href="/exhibit-with-us/exhibitor-query-form"
                className="registration-anchor"
              >
                EXHIBITOR REGISTRATION
              </a>
            </button>
          </Link>
        </div>
      </section>
      <section className="previous-event">
        <h2 className="head--2">glimpses of previous events</h2>
        <div className="grid previous-event__album grid-container">
          <div
            className="previous-event__img1 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${exhibit?.grid?.firstimage})`,
            }}
          ></div>

          <div
            className="previous-event__img2 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${exhibit?.grid?.secondimage})`,
            }}
          >
            <div onClick={() => setOpen1(true)} className="img-grid__play">
              <FaPlay className="img-grid__playimg" />
            </div>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen1}
              videoId="N1tO6qKBn98"
              onClose={() => setOpen1(false)}
              autoplay={1}
              controls={0}
            />
          </div>

          <div className="previous-event__testimonal1">
            <br />{" "}
            <p className="text--primary previous__testimonal1-content">
              {exhibit?.grid?.quotedtext}
            </p>
            <br />
            <p className="author">{exhibit?.grid?.author}</p>
            <p className="status">{exhibit?.grid?.designation}</p>
          </div>

          <div
            className="previous-event__img3 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${exhibit?.grid?.thirdimage})`,
            }}
          ></div>

          <div
            className="previous-event__img4 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${exhibit?.grid?.fourthimage})`,
            }}
          ></div>

          <div
            className="previous-event__img5 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${exhibit?.grid?.fifthimage})`,
            }}
          >
            {/*<p className="text--primary">wedding asia 2019 event highlight</p>*/}
          </div>
          <div className="previous-event__testimonal2">
            <p className="text--accent">{exhibit?.grid?.buttonintro}</p>

            {/* <Link to="/exibit-with-us/exhibitor-query-form"> */}
            <button onClick={handelrclick} className="btn testimonal2__btn">
              {exhibit?.grid?.buttontitle}
            </button>
            {/* </Link> */}
          </div>
        </div>
      </section>
      <br />
      <br />
      <section ref={mainRef}>
        <Procedure data={exhibit.HTE} />
      </section>
      {/* <section className="exhibitors">
                <div className="grid grid-cols-2">
                    <div className="exhibitors__left">
                        <h2 className="head--2 exhibitors__left-head">exhibitors</h2>
                        <p className="text--tertiary">
                            {EventPage.exhibitor_para}
                        </p>
                        <p className="text--accent exhibitor__move">
                            {EventPage.button_para}
                        </p>
                        <button className="btn exhibitor__btn exhibitor__move">
                            exhibitor registration
                        </button>

                    </div>
                    <div className="exhibitors__right grid">
                        {logo?.map((item) => <img src={item.logo} alt={item.title} key={item.id} />)}
                    </div>
                </div>
                <button className="btn exhibitor__btn exhibitor__down">
                    exhibitor registration
                </button>
                <p className="text--accent exhibitor__down">
                    to visit, secure your invitation
                </p>
            </section> */}
      <div className="brand-partners">
        <h1>Our brand partners</h1>
        <p>
          16 years of experience has earned Wedding Asia a well-reputed and
          trustworthy position in the industry.
        </p>
      </div>
      <div className="container brand-logo-container">
        <div className="row four-gallery-row">
          {logo?.map((item) => (
            <img src={item.logo} alt={item.title} key={item.id} />
          ))}
        </div>
      </div>
      <div ref={secondRef} className="event-testimonal">
        <h2 className="head--2 legend">exhibitors testimonials</h2>
        <Testimonial />
      </div>

      <div>
        <UpcomingEvent />
      </div>
    </>
  );
};

export default ExhibitorDetails;
