import React, {useEffect, useState} from 'react';
import axios from "axios";
import style from "../../PrivacyPolicy/css/privacypolicy.module.css";
import DOMPurify from "dompurify";
import Header from "../../../components/Header/Header";
import {useParams} from "react-router-dom";

const WorkDetail = () => {
    const [wrokDewtail, setWorkDetail] = useState([]);
    const param = useParams();
    useEffect(() => {
        axios.get(`https://backend.weddingasia.in/workwithus/works/${param.title}`)
            .then((res)=>setWorkDetail(res.data))
            .catch((err)=>console.log(err))
    }, []);

    return (
        <>
            <Header color="black"/>
        <div>
            <section  className={style.mainbody}  dangerouslySetInnerHTML={{__html: wrokDewtail?.body !== undefined ? DOMPurify.sanitize(wrokDewtail?.body) : ""}}>
                </section>
        </div>
            </>
    );
};

export default WorkDetail;
