import React,{createRef,useEffect} from 'react';
import style from './css/sucess.module.css'
import Header from "../../components/Header/Header";
import {useParams} from 'react-router-dom'
import {FcCheckmark} from "react-icons/fc";

const EventInviteSucessScreen = () => {
    const param = useParams();
    const mainRef = createRef();

    useEffect(() => {
        mainRef.current.scrollIntoView();
    }, [mainRef]);
    return (
        <>
            <Header color="black"/>
        <div className={style.sccscr}>

            <h1 style={{textAlign: "center"}}>get your event invite</h1>
            <div style={{textAlign: "center"}} className={style.checked}><FcCheckmark size="50"/><h5 style={{color:'green'}}>{param.msg}</h5></div>
            <h3  ref={mainRef} style={{textAlign: "center"}}>Get Inspired</h3>


            <p style={{textAlign: "center"}}> Some bridal stories and the dreamy wedding ceremonies are sure to make you swoon. Let's have a glimpse through all of them.</p>

            <div className ="plans__grid">
                <div className={style.bridecard}>
                    <a   rel="noreferrer" href="https://weddingasia.in/wedding-stories/aditi-and-saurabh" target="_blank"><img
                        src="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/1.jpg"
                        alt="featuredCollection" width="100%" className="fexhb-img"/></a>
                    <a   rel="noreferrer" href="https://weddingasia.in/wedding-stories/aditi-and-saurabh" target="_blank">
                        <p className="featured-p-padding">Aditi And Saurabh</p>
                    </a>

                </div>
                <div className={style.bridecard}>
                    <a   rel="noreferrer" href="https://weddingasia.in/wedding-stories/hanna-and-shahrukh" target="_blank"><img
                        src="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/2.jpg"
                        alt="featuredCollection" width="100%" className="fexhb-img"/></a>
                    <a  rel="noreferrer"  href="https://weddingasia.in/wedding-stories/hanna-and-shahrukh" target="_blank">
                        <p className="featured-p-padding">Hanna And Shahrukh</p>
                    </a>

                </div>
                <div className={style.bridecard}>
                    <a   rel="noreferrer" href="https://weddingasia.in/wedding-stories/sethu-and-vishnu" target="_blank"><img
                        src="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/3.jpg"
                        alt="featuredCollection" width="100%" className="fexhb-img"/></a>
                    <a  rel="noreferrer"  href="https://weddingasia.in/wedding-stories/sethu-and-vishnu" target="_blank">
                        <p className="featured-p-padding">Sethu And Vishnu</p>
                    </a>

                </div>
            </div>

        </div>
            </>
    );
};

export default EventInviteSucessScreen;
