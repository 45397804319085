import React, {useEffect, useState} from 'react';
import style from './css/work.module.css'
import Header from "../../components/Header/Header";
import axios from "axios";
import {Link} from "react-router-dom";
const WorkWithUs = () => {
    const [work, setWork] = useState([]);
    useEffect(() => {
        axios.get('https://backend.weddingasia.in/workwithus/all-works')
            .then((res)=>setWork(res.data))
            .catch((err)=>console.log(err))
    }, []);

    return (
        <>
            <Header color="black"/>
            <div className={style.workbody}>
                <div>
                    <h3>open positions</h3>
                    <p>Would you like to work with us? See the departments we are hiring in and apply!</p>
                </div>
                <div>
                    {work?.map((item)=>
                    <div key={item.id} className={style.wroklist}>
                        <div className={style.wroktitle}>
                            <Link to={/work-with-us/+item.slug}>{item.title}</Link>
                        </div>
                        <div className={style.wroklistcolo}>
                            <Link to={/work-with-us/+item.slug}>View Details</Link>
                        </div>
                    </div>
                    )}
                </div>
            </div>

        </>
    );
};

export default WorkWithUs;
