import React, { useState } from "react";
import "./css/imgGrid.css";
import { FaPlay } from "react-icons/fa";
// import { Redirect } from "react-router-dom";
// import previousevent3 from "../../images/event/event-2.png";
// import previousevent4 from "../../images/event/event-3.png";
// import previousevent5 from "../../images/event/event-4.png";
import ModalVideo from "react-modal-video";
import { useHistory } from "react-router-dom";

function ImgGrid() {
  const [isOpen1, setOpen1] = useState(false);
  // const [isOpen2, setOpen2] = useState(false);
  const history = useHistory();
  const handleClick = () => {
    let path = `/exhibit-with-us`;
    history.push(path);
  };
  const handleClicktwo = () => {
    let path = `/galleries/regal-inspiration`;
    history.push(path);
  };
  const handleClickthree = () => {
    let path = `/galleries/regal-inspiration`;
    history.push(path);
  };
  return (
    <>
      <p
        className="event-showcase"
        style={{
          fontSize: 20,
          fontFamily: "Nunito, sans-serif",
          letterSpacing: 4,
          color: "#000000",
          opacity: 1,
          textAlign: "center",
          margin: "1rem auto 5%",
        }}
      >
        EVENT SHOWCASE
      </p>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen1}
        videoId="mYKtIoLVqHs"
        onClose={() => setOpen1(false)}
        autoplay={1}
        controls={0}
      />
      <div className="img__grid">
        <div
          className="gridimg-1 gridimg"
          style={{
            background: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url('https://weddingasia-backend-static-file-rest-api.s3.ap-south-1.amazonaws.com/resized.jpg')`,
          }}
        >
          <div onClick={() => setOpen1(true)} className="img-grid__play">
            <FaPlay className="img-grid__playimg" />
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen1}
            videoId="mYKtIoLVqHs"
            onClose={() => setOpen1(false)}
            autoplay={1}
            controls={0}
          />
          <div>
            <h3>wedding asia</h3>
          </div>
          <div>
            <h1>EVENT HIGHLIGHTS 2020</h1>
            <p>
              We, Wedding Asia have had the privilege of having over 5000
              exhibitors working with us as we aim towards achieving a
              camaraderie of people joining hands to make a wedding a grand
              success. Broadening our domain every day, we wish to cater our
              services to various cultures and communities.
            </p>
          </div>
        </div>
        <div
          onClick={handleClick}
          className="gridimg gridimg-2"
          style={{
            background: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url('https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/devore+resized+(1).jpg')`,
          }}
        >
          <h1>exhibit with us</h1>
        </div>
        <div
          onClick={handleClicktwo}
          className="gridimg-3 gridimg"
          style={{
            background: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url('https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/khurana+stall.jpg')`,
          }}
        >
          <h3> WEDDING ASIA MUMBAI</h3>
          <p className="expand__image">EVENT</p>
        </div>
        <div
          onClick={handleClickthree}
          className="gridimg-4 gridimg"
          style={{
            background: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url('https://weddingasia.s3.ap-south-1.amazonaws.com/website/img/Intersection+121.jpg')`,
          }}
        >
          <h3>WEDDING ASIA DELHI</h3>
          <p className="expand__image">EVENT</p>
        </div>
      </div>
    </>
  );
}

export default ImgGrid;
