import React, { useState, useEffect } from "react";
import Rcards from "./RCards/Rcards";
import style from "./css/realbide.module.css";
import axios from "axios";
const RealBride = () => {
  const [realbride, setRealbride] = useState([]);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/brides/get-all-brides")
      .then((res) => setRealbride(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <p
        style={{
          fontSize: 20,
          fontFamily: "Nunito, sans-serif",
          letterSpacing: 4,
          color: "#000000",
          opacity: 1,
          textAlign: "center",
          margin: "1rem auto 5%",
          textTransform: "uppercase",
        }}
      >
        wedding STORIES
      </p>
      <div className={style.rcards}>
        {realbride?.map((item) => (
          <Rcards key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default RealBride;
