import React from "react";
import style from "../BlogCard/css/BlogCards.module.css";
import ProgressiveImage from "react-progressive-graceful-image";
import { placeholderImage } from "../../config/config";
import { Link } from "react-router-dom";
export const BLogCards = (props) => {
  return (
    <Link to={"/blog/" + props.item.slug}>
      <div className={style.blogcard}>
        <ProgressiveImage
          src={props.item.thumbnail}
          placeholder={placeholderImage}
        >
          {(src, loading) => (
            <img
              style={{ opacity: loading ? 0.5 : 1 }}
              src={src}
              alt="animage"
            />
          )}
        </ProgressiveImage>

        <h3
          style={{
            width: 300,
            margin: "1rem auto",
            textAlign: "left",
            fontFamily: "Nunito, sans-serif",
            color: "#656565",
            textTransform: "uppercase",
          }}
        >
          {props.item.title}{" "}
        </h3>
        <p
          style={{
            width: "300px",
            margin: "2rem auto",
            textAlign: "left",
            fontFamily: "Nunito, sans-serif",
            color: "#9A9A9A",
          }}
        >
          {props.item.intro.slice(0,120)}
        </p>
        <p
          style={{
            //width: "300",
            margin: "1.5rem",
            textAlign: "left",
            fontFamily: "Nunito, sans-serif",
            color: "black",
            borderBottom: "1px solid black",
            width: "fit-content",
            paddingBottom: "12px",
          }}
        >
          Read more
        </p>
      </div>
    </Link>
  );
};
