import React from "react";
// import "./css/skeleton.module.css";
import "./css/style.css";
import PropTypes from "prop-types";
const Skeleton = ({type}) => {
  const classes = `skeleton ${type}`;
  return (
    <div className={classes}>
     
    </div>
  );
};

export default Skeleton;


Skeleton.propTypes = {
  type: PropTypes.string,
};
  
