import React from 'react'
import "./css/procedure.css"
function Procedure(props) {
    console.table(props)
    const initVal = [];

    const newData = props.data?.reduce((acc, curr, i, originalArr) => {
        if(i < originalArr.length-1) {
            acc.push({...curr, hasLine: true, lineName: originalArr[i+1].title})
        } else {
            acc.push({...curr, hasLine: false, lineName: ""})
        }
        return acc;
    }, initVal)

    return (
        <div className="procedure">
            <h1 className="procedure__head">how to exhibit</h1>
            <p className="procedure__para">If you wish to be a part of Wedding Asia Exhibitors,have a look at the steps given below.</p>
            <div className="procedure__container">
                {}
                {newData?.map((item,i)=><>
                <section>
                    <div className="procedure__box"><p>{item.stepNo}</p></div>
                    <div className="procedure__instr">
                        <h1>{item.title}</h1>
                        <p>{item.decription}</p>
                    </div>
                </section>

                <div className="procedure__further">

                    <p>{item.lineName}</p>
                    <hr className="procedure__line"/></div>
                </>)}

            </div>
        </div>
    )
}

export default Procedure
