import React from 'react'
import "./css/weddingCard.css"
import {Link} from "react-router-dom";

function WeddingCard(props) {

    return (
        <div className="weddingcard">
            <Link to={"/galleries/" + props.item.slug}>
                <img src={props.item.coverimage} alt="" className="weddingcard__img"/>
                <div className="weddingcard__content">
                    <h3>{props.item.category}</h3>
                    <p>{props.item.title}</p>
                    <p>{props.item.description}</p>
                </div>
            </Link>
        </div>
    )
}

export default WeddingCard
