import React, { createRef, useEffect } from "react";
import UpcomingEvent from "../../components/UpComingEvent/UpcomingEvent";
import Header from "../../components/Header/Header";

const EventScreen = () => {
  const mainRef = createRef();
  useEffect(() => {
    mainRef.current.scrollIntoView();
  }, [mainRef]);
  return (
    <div ref={mainRef}>
      <Header color="black" />
      <UpcomingEvent/>

    </div>
  );
};

export default EventScreen;
