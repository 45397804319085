import React, { useState, useEffect } from "react";
import style from "./css/upcoming.module.css";
import axios from "axios";
import EventCard from "./EventCard/Eventcard";
import styles from "../../layout/Gallery/css/gallery.module.css"

const UpcomingEvent = () => {
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [loadMore, setLoadMore] = useState(2);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/events/")
      .then((res) => setUpcomingEvent(res.data))
      .catch((err) => console.log(err));
  }, []);

  const showMore = () => {
    setLoadMore((prev) => prev + 1)
    axios.get(`https://backend.weddingasia.in/events/?page=${loadMore}`)
    .then((res)=>{
        setUpcomingEvent(prevState => ({...prevState,results:[...prevState.results,...res.data.results]}))
        setUpcomingEvent(prevState => ({...prevState,next:res.data.next}))
    })
        .catch((err)=>console.log(err))
}
  return (
    <>
      {/*<Header color="black"/>*/}
      <div className={style.topmrgin}>
        <p
          style={{
            fontSize: 20,
            fontFamily: "Nunito, sans-serif",
            letterSpacing: 4,
            color: "#000000",
            opacity: 1,
            textAlign: "center",
            margin: "1rem auto 5%",
            textTransform: "uppercase",
          }}
        >
          Upcoming Event
        </p>
        <div className={style.hmdis}>
          {upcomingEvent?.results?.map((item) => (
            <EventCard key={item.id} item={item} />
          ))}
        </div>
        {upcomingEvent?.next?
        <div id="show-more" onClick={showMore} className={styles.showconatainer}>
          <button className={styles.showmorebutton}>show more events</button>
        </div>: ''}
      </div>
    </>
  );
};

export default UpcomingEvent;
