import React,{useState} from 'react';
import ProgressiveImage from "react-progressive-graceful-image";
import {placeholderImage} from '../../../config/config'
import style from './css/Rcard.module.css';
import {Link} from "react-router-dom";
const Rcards = (props) => {
    const [displaycard, setDisplaycard] = useState(false);
    const clickhandler=()=>{
        setDisplaycard(displaycard=>!displaycard)
    }

    return (
        <div onClick={clickhandler} className="image" style={{padding:13}}>

                <ProgressiveImage src={props.item.profile_image} placeholder={placeholderImage}>
                    {(src, loading) => (
                        <img
                            style={{ opacity: loading ? 0.5 : 1,
                                borderRadius: "50%",
                                display: "flex",
                                textAlign: "center",
                                padding: 5,
                                border: "1px solid #ff840d",
                                margin: "0 auto",
                                cursor: "pointer"}}
                            src={src}
                            alt={props.item.alt_tag} height="98" width="98"
                        />
                    )}
                </ProgressiveImage>


            <p id="bridestory_name1" style={{
                textAlign: "center",
                fontSize: "1rem",
                letterSpacing: "0.7px",
                color: "#ff840d",
                textTransform: "capitalize",
                marginTop: "2rem",
                fontFamily: "Nunito, sans-serif"
            }}
            >
                {props.item.name}
            </p>
            {/*pop-model*/}
            <div id="modal-container" className={style.bgmodelbridestory} style={{display: displaycard?"flex":"none"}}>
                <div id="modal-content" className={style.modelconten}>
                    <img src={props.item.profile_image} className={style.modelcontentimage} alt={props.item.name}/>
                        <h1 className={style.modelcontentname}>{props.item.name}</h1>
                        <h2 className={style.modelcontentbridtobe}>{props.item.category[0]}</h2>
                        <p className={style.modelcontentpara}>{props.item.introduction.slice(0,100)}</p>
                        <div className={style.buttons}>
                            <Link to={"/wedding-stories/"+ props.item.slug}
                               className={style.fillbtn}>read more</Link>
                            <Link to={"/galleries/"+ props.item.slug} className={style.blankbtn}>wedding gallery</Link>
                        </div>
                </div>
            </div>
            {/*pop-model*/}

        </div>
    );
};

export default Rcards;
