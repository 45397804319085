import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BLogCards } from '../BlogCard/BLogCards'
import style from './css/journal.module.css'
export const Journal = () => {
    const [blog, setBlog] = useState([])
    useEffect(() => {
        axios.get('https://backend.weddingasia.in/blogs/latest-three-blogs')
        .then((res)=>setBlog(res.data))
        .catch((err)=>console.log(err))
    }, [])
    return (
        <div className={style.journalsection}>
            <p>IN THE BUZZ</p>
            <h3>JOURNAL</h3>
            <div className={style.threeblog}>
            {blog?.map((item)=><BLogCards item={item} key={item.id}/>)}
            </div>
        </div>
    )
}
