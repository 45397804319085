import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import style from "../PrivacyPolicy/css/privacypolicy.module.css";
import DOMPurify from "dompurify";
import axios from "axios";

const TermAndCondition = () => {
    const [term, setTerm] = useState([]);
    useEffect(() => {
        axios.get('https://backend.weddingasia.in/terms-and-condition')
            .then((res)=>setTerm(res.data))
            .catch((err)=>console.log(err))
    }, []);

    return (
        <div>
            <Header color="black"/>
            <section className={style.mainbody}  dangerouslySetInnerHTML={{__html: term?.body !== undefined ? DOMPurify.sanitize(term?.body) : ""}}>

            </section>

        </div>
    );
};

export default TermAndCondition;
