import React from 'react';
import style from "./css/contactuse.module.css"
import {FcCheckmark} from "react-icons/fc"
const ContactUsThankYou = (props) => {
    return (
        <div className={style.container}>
            <h1 className={style.head}>contact us</h1>
            {/*<p className={style.contact}>Call us at: <a href="#">+91</a> or meet us at <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, repellat!</a></p>*/}
            <div className={style.checked}><FcCheckmark size="50"/><p>{props.data}</p></div>
        </div>
    );
};

export default ContactUsThankYou;
