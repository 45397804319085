import React, {useEffect, useState} from 'react';
import style from './Css/contact.module.css';
import Header from "../../components/Header/Header";
import axios from "axios";
import DOMPurify from "dompurify";
import ContactUsThankYou from "../ThankYou/ContactUsThankYou";
import loadImg from '../../images/1amw.gif'

const ContactUs = () => {
    const intialdata = {
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: ""
    }
    const [contactdetail, setContactdetail] = useState({});
    const [frmdata, setFrmdata] = useState(intialdata);
    const [laoding, setLaoding] = useState(false);

    useEffect(() => {
        axios.get('https://backend.weddingasia.in/contactus/contact-page-details')
            .then((res) => setContactdetail(res.data))
            .catch((err) => console.log(err))
    }, []);


    const handelchanger = (evt) => {
        const value = evt.target.value;
        setFrmdata({...frmdata, [evt.target.name]: value})
    }
    const clickhandler = (e) => {
        e.preventDefault();
        axios.post('https://backend.weddingasia.in/contactus/create-new-contactus/', frmdata)
            .then((res) => setFrmdata(res.data))
            .catch((err) => console.log(err));

        setFrmdata(intialdata)
        setLaoding(true)
    }

    return (
        <>

            <Header color="black"/>
            {frmdata.ack?<ContactUsThankYou data={frmdata.message}/>:<>
            <div className={style.contactbody}>
                <div className={style.centerbody}>
                    <h1>
                        CONTACT US
                    </h1>
                    <section className={style.mainbody}
                             dangerouslySetInnerHTML={{__html: contactdetail?.body !== undefined ? DOMPurify.sanitize(contactdetail?.body) : ""}}>
                    </section>
                </div>

            </div>
                <div className={style.loadingbody}>
                {laoding?<><img className={style.loading} src={loadImg} alt="laoding"/></>:<>
            <form method="post" onSubmit={clickhandler} className={style.fromcredentials} id="contactform">

                <div className={style.halfleft}>
                    <input onChange={handelchanger} type="text" value={frmdata.name} className={style.halfcommon}
                           name="name" placeholder="Full name*" required/>
                </div>

                <div className={style.halfright}>
                    <input onChange={handelchanger} type="email" value={frmdata.email} className={style.halfcommon}
                           name="email" placeholder="Email id*" required/>
                </div>

                <input onChange={handelchanger} type="text" value={frmdata.mobile} className={style.full} name="mobile"
                       placeholder="Your phone number*" required/>

                <input onChange={handelchanger} type="text" value={frmdata.subject} className={style.full}
                       name="subject" placeholder="Subject"/>

                <textarea  onChange={handelchanger} name="message" value={frmdata.message} className={style.fulltextarea}
                          placeholder="Type your message here....*"
                          required></textarea>

                <input type="submit" name="submit" value="SUBMIT" className={style.submitbtn}/>
            </form></> }
                </div>
        </>}
        </>
    );
};

export default ContactUs;
