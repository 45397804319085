import React, { useState } from 'react'
import "./css/header.css"
import logo from "../../images/Resized White.png";
import logos from "../../images/Resized Black.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
function Header(props) {
  const [toggle, setToggle] = useState(true);
  const toggler = () => {
    setToggle(!toggle);
  };
  return (
    <header style={{ borderBottom: `1px solid ${props.color}`, backgroundColor: `${props.background}` }} className="header">
      <div className="header__container">
        <div
          className="header__response"
          onClick={toggler}
          style={{ marginLeft: "0.8rem" }}
        >
          {toggle ? (
            <div>
              <GiHamburgerMenu size="24" />
            </div>
          ) : (
            <AiOutlineClose size="24" />
          )}
        </div>
        <Link to="/">
          <picture>
            <source media="(max-width:768px)" srcSet={logos} />
            {props.color === 'black' ? <img src={logos} alt="logo" /> : <img src={logo} alt="logo" />}

          </picture>
        </Link>

        <div className="header__response"></div>
      </div>
      <nav className={toggle ? "hide header__nav" : "show header__nav"}>
        <ul className="header-nav__list">
          <li>
            <Link style={{ color: `${props.color}` }} to="/events/upcomming-events/">upcoming events</Link>
          </li>
          <li>
            <Link style={{ color: `${props.color}` }} to="/exhibit-with-us">exhibit with us</Link>
          </li>

          <li>
            <Link style={{ color: `${props.color}` }} to="/galleries">gallery</Link>
          </li>
          <li>
            <a rel="noreferrer" style={{ color: `${props.color}` }} target="_blank" href="https://weddingasiaonlineshop.com/">online store</a>
          </li>
          <li>
            <Link to="/journal" style={{ color: `${props.color}` }} href="https://weddingasiaonlineshop.com/">JOURNAL
            </Link>
          </li>
          <li>
            <Link style={{ color: `${props.color}` }} to="/event-invite/visitors">Register</Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
