import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Navigation from "./navigation/Navigation";
import Footer from "./components/Footer/Footer";
// import Header from "./components/Header/Header";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  //const TRACKING_ID = ""; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize("107005611-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const linkRef = useRef();
  useEffect(() => {
    linkRef.current.click();
  }, []);
  return (
    <Router>
      <Navigation />
      <a ref={linkRef} href={"https://weddingasiaofficial.com/"}>
        Click to open HereWeCode (new tab)
      </a>
      <Footer />
      <ToastContainer />
    </Router>
  );
}

export default App;
