import React, { createRef,useState,useEffect } from "react";
import "./css/exhibitorForm.css";
import Header from "../Header/Header";
//import {BrowserRouter as Router} from "react-router-dom";
import loadingImg from "../../images/1amw.gif"
import axios from "axios";
import style from '../../layout/ContactUs/Css/contact.module.css'

import ExhbitiThank from "../ExhibtorThank/ExhbitiThank";
function ExhibitorForm() {
  const [exhibitdetails, setExhibitdetails] = useState({});
  const [resData, setResData] = useState({});

  const [loading, setLoading] = useState(false);

  const mainRef = createRef();
  useEffect(() => {
    mainRef.current.scrollIntoView();
  }, [mainRef]);

  useEffect(() => {
  axios.get("https://backend.weddingasia.in/exhibitor/exhibitor-query-form-details")
      .then((res)=>setExhibitdetails(res.data))
      .catch((err)=>console.log(err))
  }, []);

  const intialValue = {
    brand: "",
    contactperson: "",
    addressone: "",
    addresstwo: "",
    email: "",
    city: "",
    mobile: "",
    landLine: "",
    socialmedialink: "",
    socialmedianame: "",
    expertise: "",
    products: "",
    event: "",
    message: "",
  };

  const onSubmit = (e) => {
    e.preventDefault();
    axios.post('https://backend.weddingasia.in/exhibitor/create-new-exhibitor/',value)
        .then((res)=>setResData(res.data))
        .catch((err)=>console.log(err))
    setValue(intialValue)
    setLoading(true)

  };

  const [value, setValue] = useState(intialValue);
  return (
      <>
        <Header color="black" />
        <div ref={mainRef} className="form__container">
          <h1 className="upcs">exhibitors query form</h1>
          {resData.ack?<ExhbitiThank msg={resData.message}/>:<>
          <div className="form__heading">
            <p>
              Kindly fill in the form provided below for us to look at your requests
              and address them accordingly
            </p>
          </div>
          <div className={style.loadingbody}>
          {loading?<><img className={style.loading} src={loadingImg} alt="laoding"/></>:<>
          {/* form */}
          <form className="form" onSubmit={onSubmit}>
            <div className="form__division form__address">
              <input
                  type="text"
                  placeholder="Brand name*"
                  className="form__inputs w-50"
                  value={value.brand}
                  onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        brand: e.target.value,
                      }))
                  }
                  required
              />
              <input
                  type="text"
                  placeholder="Contact Person*"
                  className="form__inputs w-50"
                  value={value.contactperson}
                  onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        contactperson: e.target.value,
                      }))
                  }
                  required
              />
            </div>
            <div className="form__division">
              <input
                  type="text"
                  placeholder="Address line 1*"
                  className="form__inputs w-50"
                  value={value.addressone}
                  onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        addressone: e.target.value,
                      }))
                  }
                  required
              />
              <input
                  type="text"
                  placeholder="Address line 2*"
                  className="form__inputs w-50"
                  value={value.addresstwo}
                  onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        addresstwo: e.target.value,
                      }))
                  }
                  required
              />
            </div>
            <div className="form__division">
              <input
                  type="email"
                  placeholder="Email*"
                  className="form__inputs w-50"
                  value={value.email}
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, email: e.target.value }))
                  }
                  required
              />
              <select
                  type="text"
                  placeholder="City*"
                  className="form__inputs w-50"
                  //value={value.city}
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, city: e.target.value }))
                  }
                  required
              >
                  <option value='' selected disabled>City*</option>
                {exhibitdetails?.cities?.map((item)=> <option key={item.id} value={item.slug}>{item.title}</option>)}
                </select>
            </div>
            <div className="form__division">
              <input
                  type="tel"
                  placeholder="Mobile*"
                  className="form__inputs w-50"
                  value={value.mobile}
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, mobile: e.target.value }))
                  }
                  required
              />
              <input
                  type="text"
                  placeholder="Landline(if any)"
                  className="form__inputs w-50"
                  value={value.landLine}
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, landLine: e.target.value }))
                  }
                  
              />
            </div>
            <div className="form__division">
              <select
                  type="text"
                  className="form__inputs w-50"

                  onChange={(e) =>
                      setValue((prev) => ({ ...prev,  socialmedianame: e.target.value }))
                  }
                  required
              >
                <option  value='' selected disabled>Social Media Platform*</option>
                {exhibitdetails?.socialmedias?.map((item)=> <option key={item.id} value={item.slug}>{item.name}</option>)}
              </select>
              <input
                  type="text"
                  placeholder="Social Media handle(facebook/Instagram/twitter)*"
                  className="form__inputs w-50"
                  value={value.socialmedialink}
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev,  socialmedialink: e.target.value }))
                  }
                  required
              />
            </div>
            <div className="form__division">
              <select
                  type="text"
                  className="form__inputs w-50"
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, products: e.target.value }))
                  }
                  required
              >

                <option  value='' selected disabled>Products or services*</option>
                {exhibitdetails?.productsorservices?.map((item)=> <option key={item.id} value={item.slug}>{item.name}</option>)}
              </select>
              <input
                  type="text"
                  placeholder="Year of expertise*"
                  className="form__inputs w-50"
                  value={value.expertise}
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, expertise: e.target.value }))
                  }
                  required
              />
            </div>
            <div>
              <select
                  name=""
                  id=""
                  className="form__inputs w-100"
                  onChange={(e) =>
                      setValue((prev) => ({ ...prev, event: e.target.value }))
                  }
                  required
                  placeholder="hi"
              >
                <option  value='' selected disabled>Select Event you wish to participate*</option>
                {exhibitdetails?.events?.map((item)=> <option key={item.id} value={item.slug}>{item.title}|{item.event_date}</option>)}
              </select>
            </div>
            <div>
          <textarea
              name=""
              id=""
              rows="4"
              className="form__inputs w-100"
              placeholder="Message"
              value={value.message}
              onChange={(e) =>
                  setValue((prev) => ({ ...prev, message: e.target.value }))
              }
              
          />
            </div>
            <button className="form__btn" type="submit">
              submit
            </button>
          </form></>}
          </div>
          <div className="paralast">
        <p>
          We will be able to analyse the best if the form is filled with all the necessary details. Truly appreciate your time and effort.
        </p>
      </div></>}
          {/* form-end */}
        <p></p>
        {" "}
        </div>
        </>
  );
}

export default ExhibitorForm;
