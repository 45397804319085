import React from 'react'
import FeaturedColection from '../../components/FeaturedColection/Plans'
import {Journal} from '../../components/Journal/Journal'
import Carousel from "../../components/Carousel/Carousel";
import ImgGrid from "../../components/ImgGrid/ImgGrid";
import IntroduceTobe from "../../components/IntroduceBrideTobe/IntroduceTobe";
import Header from "../../components/Header/Header";
import RealBride from "../../components/RealBride/RealBride";
import UpcomingEvent from "../../components/UpComingEvent/UpcomingEvent";


export const Home = () => {
    return (
        <div>
            <Header color="white"/>
            <Carousel/>
            <UpcomingEvent/>
            <ImgGrid/>
            <br/>
            <br />
            <IntroduceTobe/>
            <RealBride/>
            <div style={{margin: "0, auto",   textAlign: "center",}}>
    <h2 class="featured-collection" style={{
        fontSize: "20px",
        fontFamily: "Nunito, sans-serif",
        letterSpacing: "4px",
        lineHeight: "27px",
        color: "#000000",
        opacity: "1",
        textAlign: "center",
        marginTop: "2rem",
        textTransform: "uppercase",
    }}>
      Magnificent Bridal Collection
    </h2>
    <p>
      Have a glance at the featured collection of Wedding Asia online shop.
    </p>
  </div>
            <FeaturedColection/>
            <Journal/>


        </div>
    )
}
