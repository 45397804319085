import React from "react";
// import style from './css/eventdetail.module.css';
import Event from "../../components/Event/Event";
const EventDetail = () => {
  return (
    <>
      <Event />
    </>
  );
};

export default EventDetail;
