import React, {useState,useEffect} from "react";
import "./css/form.css";
import axios from "axios";
import {Redirect} from "react-router-dom";
import loadingImg from "../../images/1amw.gif";

function Visitor() {

    const intialValue = {
        name: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        state: "",
        mobile: "",
        email: "",
        purposeofvisit: "",
        event: "",
        message: "",
    };
    const [details, setDetails] = useState({});
    const [resData, setResData] = useState({});
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(intialValue);

    useEffect(() => {
        axios.get("https://backend.weddingasia.in/event-invite/event-invite-page-details")
            .then((res) => setDetails(res.data))
            .catch((err) => console.log(err))
    }, []);
    const onSubmit = (e) => {
        e.preventDefault();
        axios.post('https://backend.weddingasia.in/event-invite/visitors/',value)
            .then((res)=>{
                setLoading(true)
                setResData(res.data)
            })
            .catch((err)=>console.log(err))
        setValue(intialValue)
        setLoading(true)
    };
    return (<>
        {resData?.ack?<Redirect to={'/event-invite/thank-you/' + resData.message} />:''}
        {loading?<div className="loadingimg"><img style={{width:400,textAlign:"center"}} src={loadingImg}/> </div>:
        <form className="form" onSubmit={onSubmit}>
            <div>
                <input
                    type="text"
                    placeholder="Your Name*"
                    className="form__inputs w-100"
                    value={value.name}
                    onChange={(e) =>
                        setValue((prev) => ({...prev, name: e.target.value}))
                    }
                    required
                />
            </div>
            <div className="form__division form__address">
                <input
                    type="text"
                    placeholder="Address 1*"
                    className="form__inputs w-50"
                    value={value.addressOne}
                    onChange={(e) =>
                        setValue((prev) => ({
                            ...prev,
                            addressOne: e.target.value,
                        }))
                    }
                    required
                />{" "}
                <input
                    type="text"
                    placeholder="Address 2*"
                    className="form__inputs w-50"
                    value={value.addressTwo}
                    onChange={(e) =>
                        setValue((prev) => ({
                            ...prev,
                            addressTwo: e.target.value,
                        }))
                    }
                    required
                />
            </div>
            <div className="form__division">
                <select
                    type="text"
                    placeholder="City*"
                    className="form__inputs w-50"
                    value={value.city}
                    onChange={(e) =>
                        setValue((prev) => ({
                            ...prev,
                            city: e.target.value,
                        }))
                    }
                    required
                >
                                        <option value="" selected disabled>City*</option>
                    {details?.cities?.map((item)=> <option key={item.id} value={item.slug} >{item.title} </option>)}
                    </select>
                <select
                    type="text"
                    placeholder="State*"
                    className="form__inputs w-50"
                    value={value.state}
                    onChange={(e) =>
                        setValue((prev) => ({
                            ...prev,
                            state: e.target.value,
                        }))
                    }
                    required
                >
                            <option value="" selected disabled>State*</option>
                    {details?.states?.map((item)=> <option key={item.id} value={item.slug} >{item.title} </option>)}
                    </select>
            </div>
            <div className="form__division">
                <input
                    type="tel"
                    placeholder="Mobile Number*"
                    className="form__inputs w-50"
                    value={value.mobile}
                    onChange={(e) =>
                        setValue((prev) => ({...prev, mobile: e.target.value}))
                    }
                    required
                />{" "}
                <input
                    type="email"
                    placeholder="Email*"
                    className="form__inputs w-50"
                    value={value.email}
                    onChange={(e) =>
                        setValue((prev) => ({...prev, email: e.target.value}))
                    }
                    required
                />
            </div>
            <div className="">
                <input
                    type="text"
                    placeholder="Purpose of visit*"
                    className="form__inputs w-100"
                    value={value.purposeofvisit}
                    onChange={(e) =>
                        setValue((prev) => ({...prev, purposeofvisit: e.target.value}))
                    }
                    required
                />
            </div>
            <div>
                <select
                    name=""
                    id=""
                    className="form__inputs w-100"
                    value={value.event}
                    onChange={(e) =>
                        setValue((prev) => ({...prev, event: e.target.value}))
                    }
                    required
                >
                    <option value="" selected disabled>Select event you wish to visit*</option>
                    {details?.events?.map((item)=> <option key={item.id} value={item.slug} >{item.title} | {item.event_date}</option>)}
                </select>
            </div>
            <div>
        <textarea
            name=""
            id=""
            rows="4"
            className="form__inputs w-100"
            placeholder="Message"
            value={value.message}
            onChange={(e) =>
                setValue((prev) => ({...prev, message: e.target.value}))
            }

        />
            </div>
            <button className="form__btn">get invite</button>

        </form>}
            <p style={{textAlign:'center'}}>{resData.message}</p>
        </>
    );
}

export default Visitor;
