import React from 'react';
import style from './css/IntroduceTodo.module.css'
const IntroduceTobe = () => {
    return (
        <div className={style.introduce}>
            <div className={style.textarea}>
                <div className={style.heading}>UNMATCHED BRIDAL ENSEMBLE</div>
                <div className={style.mobView}>
                    <img src="https://weddingasia.s3.ap-south-1.amazonaws.com/website/img/Rectangle+2249.jpg" width="100%" alt=""/>
                </div>
                <p className={style.desc}>It's time for the bride-to-be to gather all her accessories for the perfect ensemble for her wedding day.</p>

                <div className={style.button}>
                    <a href="/event-invite/bride-to-be">
                        Get Invitation
                    </a>
                </div>
            </div>
            <div id={style.right} >
                <img src="https://weddingasia.s3.ap-south-1.amazonaws.com/website/img/Rectangle+2249.jpg" width="100%" alt=""/>
            </div>
        </div>
    );
};

export default IntroduceTobe;
