import React, { useState, useEffect } from "react";
import "./css/footer.css";
import loadingImg from "../../images/1amw.gif";

import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
import { TiSocialFacebook } from "react-icons/ti";
import { ImLinkedin2 } from "react-icons/im";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Footer() {
  const [footer, setFooter] = useState({});
  const [newsletter, setNewsletter] = useState({ email: "" });
  const [ress, setRess] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/footer/details")
      .then((res) => setFooter(res.data))
      .catch((err) => console.log(err));
  }, []);

  const sendRequest = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "https://backend.weddingasia.in/footer/create-newsletter/",
        newsletter
      )
      .then((res) => {
        setRess(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setNewsletter({ email: "" });
  };

  return (
    <footer className="footer">
      <div className="grid footer__container">
        <div className="footer__left">
          <div className="footer__left-logo">
            <img src={footer?.footercontent?.waLogo} alt="logo" />
          </div>
          <p className="footer__left-text">
            {footer?.footercontent?.shortIntro}
          </p>
          <ul className="footer__left-list footer__list">
            <li className="footer__left-items">
              <a href={footer?.footercontent?.facebookLink} target="blank">
                <TiSocialFacebook size="24" fill="#C5C5C5" />
              </a>
            </li>
            <li className="footer__left-items">
              <a href={footer?.footercontent?.twitterLink} target="blank">
                <AiOutlineTwitter size="24" fill="#C5C5C5" />
              </a>
            </li>
            <li className="footer__left-items">
              <a href={footer?.footercontent?.linkedinLink} target="blank">
                <ImLinkedin2 size="24" fill="#C5C5C5" />
              </a>
            </li>
            <li className="footer__left-items">
              <a href={footer?.footercontent?.instagramLink} target="blank">
                <AiOutlineInstagram size="24" fill="#C5C5C5" />
              </a>
            </li>
            <li className="footer__left-items">
              <a href={footer?.footercontent?.youtubeLink} target="blank">
                <AiFillYoutube size="24" fill="#C5C5C5" />
              </a>
            </li>
          </ul>
          <input
            type="text"
            placeholder="Enter your email address"
            className="footer__left-input exhibitor__down"
          />
          <button className="btn exhibitor__down">
            subscribe to newsletter
          </button>
        </div>
        <div className="footer__right grid grid-cols-2">
          <div>
            <h3 className="footer__head">quick links</h3>
            <ul className="footer__right-list footer__list">
              {footer?.quicklinks?.map((item) => (
                <li key={item.id}>
                  <Link
                    rel="noreferrer"
                    to={item.link}
                    target={item.linkTitle === "Shop Online" ? "_blank" : ""}
                  >
                    {item.linkTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="loadingimag">
            <h3 className="footer__head footer__right-newsletter exhibitor__move">
              SUBSCRIBE
            </h3>{" "}
            <br />
            <p className="footer__right-text exhibitor__move">
              {footer?.footercontent?.subscribeText}
            </p>
            {loading ? (
              <img src={loadingImg} alt="logo" />
            ) : (
              <form onSubmit={sendRequest}>
                <input
                  onChange={(e) =>
                    setNewsletter((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  type="email"
                  value={newsletter.email}
                  placeholder="Enter your email address"
                  className="footer__left-input exhibitor__move"
                  required
                />
                <button className="btn footer__right-btn  exhibitor__move">
                  SUBSCRIBE
                </button>
              </form>
            )}
            <p>{ress.message}</p>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <hr />
        <p>wedding asia - &copy; 2024.all rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
