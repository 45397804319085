import React, { useState, useEffect } from 'react';
import style from "./css/gallery.module.css"
import Header from "../../components/Header/Header";
import axios from "axios";
import WeddingCard from "../../components/WeddingCard/WeddingCard";

const Gallery = () => {
    const [gallery, setGallery] = useState({});
    const [loadMore, setLoadMore] = useState(2);
    const [finalData,] = useState({});
    useEffect(() => {
        axios.get(`https://backend.weddingasia.in/galleries/`)
            .then((res) => setGallery(res.data))
            .catch((err) => console.log(err))
    }, []);

    const showMore = () => {
        setLoadMore((prev) => prev + 1)
        axios.get(`https://backend.weddingasia.in/galleries/?page=${loadMore}`)
            .then((res) => {
                setGallery(prevState => ({ ...prevState, results: [...prevState.results, ...res.data.results] }))
                setGallery(prevState => ({ ...prevState, next: res.data.next }))
            })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <Header color="white" />
            <div className={style.headercontainer}>
                <div className={style.hddetail}>
                    <h2 className={style.galleryheader}>GALLERY</h2>
                </div>
            </div>
            <div className={style.galbody}>
                {gallery?.results?.map((item) => <WeddingCard key={item.id} item={item} />)}
                {finalData?.results?.map((item) => <WeddingCard key={item.id} item={item} />)}
            </div>
            {gallery?.next ?
                <div id="show-more" className={style.showconatainer}>
                    <button onClick={showMore} className={style.showmorebutton}>
                        show more events &#8794;
                    </button>
                </div> : ''}
        </>
    );
};

export default Gallery;
