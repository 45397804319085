import React, { useState,useEffect } from "react";

import "./css/testimonial.css";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import axios from "axios";
function Testimonial() {
  const [dataList, setDataList] = useState([]);
  const [current, setCurrent] = useState(0);
  const [x, setX] = useState(0);
  const moveLeft = () => {
    x === 0 ? setX(-106 * (dataList.length - 1)) : setX(x + 106);
    setCurrent(current === 0 ? dataList.length - 1 : current - 1)
  };
  const moveRight = () => {
    x === -106 * (dataList.length - 1) ? setX(0) : setX(x - 106);
    setCurrent(current === dataList.length - 1 ? 0  : current + 1)
  };


  useEffect(() => {
    axios.get('https://backend.weddingasia.in/exhibitor/testimonials')
        .then((res)=>setDataList(res.data))
        .catch((err)=>console.log(err))
  }, []);

  return (
      <div className="exhibitor__testimonal">
        <div className="custom__slider">
          { window.innerWidth < 769 ? dataList.map((data, i) => {
                return (
                    <React.Fragment key={i}>
                      <div className="custom__slide">
                        <div className="event__testimonial-content">
                          <p className="text">{data?.comment}</p>
                          <p className="author">{data?.name}</p>
                          <p className="author">{data?.designation}</p>
                          <div className="item-rating">
                            {Array(dataList[current]?.ratings)
                                .fill()
                                .map((_, i) => (
                                    <p key={i}><img src="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/starfill.png" alt="start"/></p>
                                ))}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                );
              }) :
              (
                  <div className="custom__slide">
                    <div className="event__testimonial-content">
                      <p className="text">{dataList[current]?.comment}</p>
                      <p className="author">{dataList[current]?.name}</p>
                      <p className="status">{dataList[current]?.designation}</p>
                      <div className="item-rating">
                        {Array(dataList[current]?.ratings)
                            .fill()
                            .map((_, i) => (
                                <p key={i}><img src="https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/starfill.png" alt="start"/></p>
                            ))}
                      </div>
                    </div>
                    <div className="exhibitor__testimonal-img">
                      {dataList.map((item,i) => {
                        return (
                            <img src={item.image} alt="" className="test_slides" key={i} style={current < i ?{transform: `translateX(${x}%)`,height:"96%"} : {transform: `translateX(${x}%)`}}/>
                        )
                      })}

                    </div>
                  </div>)
          }
          <button className="custom__left-arrow custom__button" onClick={moveLeft}>
            <AiOutlineLeft size="20" fill="#707070" />
          </button>
          <button className="custom__right-arrow custom__button" onClick={moveRight} >
            <AiOutlineRight size="20" fill="#707070" />
          </button>
        </div>
      </div>
  );
}

export default Testimonial;
