import React, { useState, useEffect } from "react";
import "./css/event.css";
import '../ImgGrid/css/imgGrid.css'
import { FaPlay } from "react-icons/fa";
import { GrWorkshop } from "react-icons/gr";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineDownload } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";
import { AiOutlineTwitter, AiFillYoutube } from "react-icons/ai";
import { TiSocialFacebook } from "react-icons/ti";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import axios from "axios";
import Testimonial from "../Testimonial/Testimonial";
import ModalVideo from "react-modal-video";
import { useHistory } from "react-router-dom";
import moment from "moment";
function Event() {
  const [EventDetail, setEventDetail] = useState({});
  const [EventPage, setEventPage] = useState({});
  const [logo, setLogo] = useState([]);
  const param = useParams();
  const history = useHistory();
  const [isOpen1, setOpen1] = useState(false);


  useEffect(() => {
    axios
      .get(`https://backend.weddingasia.in/events/event/${param.title}`)
      .then((res) => setEventDetail(res.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/events/page-details")
      .then((res) => setEventPage(res.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.weddingasia.in/partner/")
      .then((res) => setLogo(res.data))
      .catch((err) => console.log(err));
  }, []);
  const handlerone = () => {
    let path = `/event-invite/visitors`;
    history.push(path);
  };

  const handlertne = () => {
    let path = `/exhibit-with-us`;
    history.push(path);
  };
  const prhnadler = () => {
    window.location.href =
      "https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/WA+Post+Event+Report+2020.pdf";
  };
  const grhnadler = () => {
    window.location.href = "https://weddingasia.in/galleries/regal-inspiration";
  };
  const knhandler = () => {
    window.location.href =
      "https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/About+Wedding+Asia.pdf";
  };
  const handlervistir = () => {
    window.location.href = "https://weddingasia.in/event-invite/visitors";
  };
  const handlerssd = () => {
    window.location.href =
      "https://weddingasia.in/exhibit-with-us/exhibitor-query-form";
  };

  moment.locale();
  const sTime = moment(EventDetail?.start_time, "HH:mm:ss");
  const eTime = moment(EventDetail?.end_time, "HH:mm:ss");

  return (
    <div className="event">
      <Header color="black" />
      <div
        className="event__heros"
        style={{
          backgroundImage: `linear-gradient(269deg, #000000 0%, #00000075 0%, #000000 100%),
      url(${EventDetail?.cover_image})`,
        }}
      >
        <div className="event__hero-container">
          <h1 className="head--1 event__hero-head">{EventDetail?.title}</h1>
          <p className="event__hero-date"> {EventDetail?.event_date}</p>
          <p className="event__hero-time">
            {sTime.format("h:mm A")}-  {eTime.format("h:mm A")}
          </p>
          <address>{EventDetail?.venue}</address>
          <div className="btnss">
            <button onClick={handlerone} className="btn">
              visitor registration
            </button>
            <button onClick={handlertne} className="btn">
              exhibitors query
            </button>
          </div>
        </div>
        <div className="event__hero-location">
          <div className="event__hero-locates">
            <div onClick={prhnadler} className="event__hero-media">
              <GrWorkshop size="28" />
              <div>
                <div>{EventPage.box_one_title}</div>
                {/*<div>20+ workshop</div>*/}
              </div>
            </div>
            <div onClick={grhnadler} className="event__hero-media">
              <VscAccount size="28" />
              <div>
                <div>{EventPage.box_two_title}</div>
                {/*<div>20+ workshop</div>*/}
              </div>
            </div>
            <div className="event__hero-media">
              <IoLocationSharp size="28" />
              <div>
                <div>
                  {EventPage.box_three_title} {EventDetail?.venue}
                </div>
                {/*<div>20+ workshop</div>*/}
              </div>
            </div>
            <div onClick={knhandler} className="event__hero-media">
              <AiOutlineDownload size="28" />
              <div>
                <div>{EventPage.box_four_title}</div>
                {/*<div>20+ workshop</div>*/}
              </div>
            </div>
          </div>
          <div className="event-scroll">
            scroll <BsArrowRight size="30" style={{ marginLeft: "10px" }} />
          </div>
          <div className="even__hero-share">
            <div>
              <a
                rel="noreferrer"
                style={{ color: "white" }}
                target="_blank"
                href="https://twitter.com/weddingasia_?lang=en"
              >
                <AiOutlineTwitter /> <span>Twitter</span>
              </a>
            </div>
            <div>
              <a
                rel="noreferrer"
                style={{ color: "white" }}
                target="_blank"
                href="https://www.facebook.com/weddingasiaofficial/"
              >
                <TiSocialFacebook />
                <span style={{ fontSize: 14 }}>Facebook</span>
              </a>
            </div>
            <div>
              <a
                rel="noreferrer"
                style={{ color: "white" }}
                target="_blank"
                href="https://www.youtube.com/channel/UCZqmNv-JUj0_evWZPOke1Pg"
              >
                <AiFillYoutube /> <span style={{ fontSize: 14 }}>Youtube</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="event__hero-locate media-mobile">
        <div onClick={prhnadler} className="event__hero-media">
          <GrWorkshop size="28" />
          <div>
            <div>{EventPage.box_one_title}</div>
            {/*<div>20+ workshop</div>*/}
          </div>
        </div>
        <div onClick={grhnadler} className="event__hero-media">
          <VscAccount size="28" />
          <div>
            <div>{EventPage.box_two_title}</div>
            {/*<div>20+ workshop</div>*/}
          </div>
        </div>
        <div className="event__hero-media">
          <IoLocationSharp size="28" />
          <div>
            <div>{EventPage.box_three_title}</div>
            {/*<div>20+ workshop</div>*/}
          </div>
        </div>
        <div onClick={knhandler} className="event__hero-media">
          <AiOutlineDownload size="28" />
          <div>
            <div>{EventPage.box_four_title}</div>
            {/*<div>20+ workshop</div>*/}
          </div>
        </div>
      </div>
      <section className="event__media-container">
        <div className="event__media grid grid-cols-2 grid-gap">
          <img
            src={EventDetail?.season_image}
            alt=""
            className="event__media-img"
          />
          <div>
            <h1 className="event__media-head">what's new this season</h1>
            <h2 className="event__media-year">{EventPage.this_year}</h2>
            <div className="event__media-texts grid">
              <p className="event__media-text text--secondary">
                {EventDetail?.para1}
              </p>
              <p className="event__media-text text--secondary">
                {EventDetail?.para2}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="previous-event">
        <h2 className="head--2">glimpses of previous events</h2>
        <div class="grid previous-event__album grid-container">
          <div
            className="previous-event__img1 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${EventDetail?.grid?.first_image})`,
            }}
          ></div>

          <div
            className="previous-event__img2 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${EventDetail?.grid?.second_image})`,
            }}
          >
            <div onClick={() => setOpen1(true)} className="img-grid__play">
              <FaPlay className="img-grid__playimg" />
            </div>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen1}
              videoId="N1tO6qKBn98"
              onClose={() => setOpen1(false)}
              autoplay={1}
              controls={0}
            />
          </div>

          <div className="previous-event__testimonal1">
            <p className="text--primary previous__testimonal1-content">
              {EventDetail?.grid?.quoted_text}
            </p>
            <br />
            <br />
            <p className="author">{EventDetail?.grid?.author}</p>
            <p className="status">{EventDetail?.grid?.desig}</p>
          </div>

          <div
            className="previous-event__img3 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${EventDetail?.grid?.third_image})`,
            }}
          ></div>

          <div
            className="previous-event__img4 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${EventDetail?.grid?.fourth_image})`,
            }}
          ></div>

          <div
            className="previous-event__img5 previous__event__images"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%),
      url(${EventDetail?.grid?.fifth_image})`,
            }}
          >
            {/*<p className="text--primary">wedding asia 2019 event highlight</p>*/}
          </div>
          <div className="previous-event__testimonal2">
            <p className="text--accent">
              {EventDetail?.grid?.button_sub_intro}
            </p>
            <button onClick={handlervistir} className="btn testimonal2__btn">
              {EventDetail?.grid?.button_title}
            </button>
          </div>
        </div>
      </section>
      <section className="exhibitors">
        <div className="grid grid-cols-2">
          <div className="exhibitors__left">
            <h2 className="head--2 exhibitors__left-head">exhibitors</h2>
            <p className="text--tertiary">{EventPage.exhibitor_para}</p>
            <p className="text--accent exhibitor__move">
              {EventPage.button_para}
            </p>
            <button
              onClick={handlerssd}
              className="btn exhibitor__btn exhibitor__move"
            >
              exhibitor registration
            </button>
          </div>
          <div className="exhibitors__right grid">
            {logo?.map((item) => (
              <img src={item.logo} alt={item.title} key={item.id} />
            ))}
          </div>
        </div>
        <button className="btn exhibitor__btn exhibitor__down">
          exhibitor registration
        </button>
        <p className="text--accent exhibitor__down">
          to visit, secure your invitation
        </p>
      </section>

      <div className="event-testimonal">
        <h2 className="head--2 legend">exhibitors testimonials</h2>
        <Testimonial />
      </div>
      <div
        className="container my_container my_container_location"
        id="get-direction"
      >
        {/* <div className="locationContainer">
            <h1 >LOCATION</h1>
            <h2>
              {EventDetail?.venue}
            </h2>
            <p>
              SEE ON GOOGLE MAPS
            </p>
          </div> */}

        {/* <iframe
              src={EventDetail?.google_map}
              style={{width:"100%",height:450}}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
          ></iframe> */}
      </div>
    </div>
  );
}

export default Event;
