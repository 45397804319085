import React,{useEffect,useState} from 'react';
import style from './css/mixBlog.module.css';
import Header from "../../components/Header/Header";
import axios from "axios";
import {MixBlogCard} from "../../components/MixBlogCard/MixBlogCard";

const MixBlog = () => {
    const [journal, setJournal] = useState([]);

    useEffect(() => {
        axios.get('https://backend.weddingasia.in/journals')
            .then((res)=>setJournal(res.data))
            .catch((err)=>console.log(err))
    }, []);

    return (
        <>
            <Header color='black' />
            <div className={style.mixblogtop}>
                {journal?.map((item)=><MixBlogCard key={item.id} item={item} />)}
            </div>
        </>
    );
};

export default MixBlog;
